<!-- Mto particular de Jefes de Zona -->

<template>
  <div class="jefeszona_M" v-if="schema">
    <v-sheet :elevation="4">

      <!-- Cabecera -->
      <div class="cabecera">
        <base_Header
          v-if="Entorno.header.header"       
          :Entorno="Entorno.header"
          @onEvent="event_Header">
        </base_Header>
      </div> 

      <!-- Botoneras -->
      <div class="pt-2 d-flex justify-left">
        <!-- Mto --> 
        <div style="justify-content:space-between;width:300px">           
          <btramto
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="m"
              :estado="estado"
              :nopesoShow=1
              @onEvent="event_btra_Mto">        
          </btramto>
        </div>

        <!-- Ventana de Documentos -->
        <common_Docs
          :padre="stName"
          :id="record.id"
          :show="schema.btra.extra.docs"
          tip="11">
        </common_Docs>
      </div>

      <!-- Controles del Mto -->
      <div class="conflex contenedor">  
        <div class="columna" style="width:70%">
            
          <v-text-field
            style="font-weight: bold;"
            v-bind="$input"
            v-model="schema.ctrls.name.value"
            :label="schema.ctrls.name.label"
            :disabled="!is_edit">              
          </v-text-field>

          <div class="conflex">            
            <v-text-field
              style="flex: 1 1 70%"
              v-bind="$input"
              v-model="schema.ctrls.codigo.value"
              :label="schema.ctrls.codigo.label"
              :disabled="!is_edit">            
            </v-text-field>
          
            <uiText
              style="flex: 1 1 30%"
              v-bind="$input"
              v-model="schema.ctrls.nif.value"
              :label="schema.ctrls.nif.label"
              format="cif"
              :disabled="!is_edit">            
            </uiText>
          </div>

          <div class="conflex">                
            <v-text-field
              style="flex: 1 1 70%"
              v-bind="$input"
              v-model="schema.ctrls.dir.value"
              :label="schema.ctrls.dir.label"
              :disabled="!is_edit">            
            </v-text-field>
          
            <v-text-field
              style="flex: 1 1 30%"
              v-bind="$input"
              v-model="schema.ctrls.cpo.value"
              :label="schema.ctrls.cpo.label"
              :disabled="!is_edit">            
            </v-text-field>              
          </div>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.pob.value"
            :label="schema.ctrls.pob.label"
            :disabled="!is_edit"
          >
          </v-text-field>

          <div class="conflex">               
            <v-text-field
              style="flex: 1 1 33%"
              v-bind="$input"
              v-model="schema.ctrls.tlf1.value"
              :label="schema.ctrls.tlf1.label"
              :disabled="!is_edit">            
            </v-text-field>               
                
            <v-text-field
              style="flex: 1 1 33%"
              v-bind="$input"
              v-model="schema.ctrls.mov1.value"
              :label="schema.ctrls.mov1.label"
              :disabled="!is_edit">            
            </v-text-field>
                
            <v-text-field
              style="flex: 1 1 33%"
              v-bind="$input"
              v-model="schema.ctrls.fax1.value"
              :label="schema.ctrls.fax1.label"
              :disabled="!is_edit">            
            </v-text-field>               
          </div>

          <div class="conflex">
            <v-text-field
              style="flex: 1 1 33%"
              v-bind="$input"
              v-model="schema.ctrls.tlf2.value"
              :label="schema.ctrls.tlf2.label"
              :disabled="!is_edit">            
            </v-text-field>
              
            <v-text-field
              style="flex: 1 1 33%"
              v-bind="$input"
              v-model="schema.ctrls.mov2.value"
              :label="schema.ctrls.mov2.label"
              :disabled="!is_edit">            
            </v-text-field>
           
            <v-text-field
              style="flex: 1 1 33%"
              v-bind="$input"
              v-model="schema.ctrls.fax2.value"
              :label="schema.ctrls.fax2.label"
              :disabled="!is_edit">            
            </v-text-field>                
          </div>

          <div class="conflex">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.email1.value"
              :label="schema.ctrls.email1.label"
              :disabled="!is_edit">          
            </v-text-field>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.email2.value"
              :label="schema.ctrls.email2.label"
              :disabled="!is_edit">              
            </v-text-field>
          </div>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.zona.value"
            :label="schema.ctrls.zona.label"
            :disabled="!is_edit">          
          </v-text-field>

          <div class="conflex">             
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.passwd.value"
              :type="showpass1 ? 'text' : 'password'"
              :append-icon="showpass1 ? 'mdi-eye' : 'mdi-eye-off'"
              :label="schema.ctrls.passwd.label"
              :disabled="!is_edit"
              @click:append="showpass1 = !showpass1">            
            </v-text-field>
              
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.passwd_sus.value"
              :type="showpass2 ? 'text' : 'password'"
              :append-icon="showpass2 ? 'mdi-eye' : 'mdi-eye-off'"
              :label="schema.ctrls.passwd_sus.label"
              :disabled="!is_edit"
              @click:append="showpass2 = !showpass2">            
            </v-text-field>
          
            <v-checkbox
              v-bind="$checkbox"
              v-model="schema.ctrls.web.value"
              :label="schema.ctrls.web.label"
              :disabled="!is_edit">
            </v-checkbox>                
          </div>

          <v-textarea
            v-bind="$textarea"
            v-model="schema.ctrls.obs.value"
            no-resize
            :label="schema.ctrls.obs.label"
            :disabled="!is_edit">          
          </v-textarea>

          <compfecha style="flex: 1 1 30%" :schema="schema.ctrls.fhalta" :edicion="is_edit"></compfecha>
        </div>

        <div class="columna" style="padding-left:10px;width:30%">   
          <div class="columna">      
            <div class="cab" style="width:120px">FOTO</div>          
            <v-sheet style="width:180px" v-bind="$cfg.styles.marco"> 
              <compImagen
                :ctrl="schema.ctrls.foto"
                :record="record"
                :disabled="is_edit">
              </compImagen>
            </v-sheet>
          </div>
                    
          <compfecha :schema="schema.ctrls.fhnac" :edicion="is_edit"></compfecha>

          <v-select
            v-bind="$select"
            v-model="schema.ctrls.ecivil.value"
            :label="schema.ctrls.ecivil.label"
            :items="get_estadoCivil"
            :disabled="!is_edit"
            item-value="id"
            item-text="name">          
          </v-select>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.catpro.value"
            :label="schema.ctrls.catpro.label"
            :disabled="!is_edit">          
          </v-text-field>

          <v-textarea
            v-bind="$textarea"
            v-model="schema.ctrls.estudios.value"
            no-resize
            :label="schema.ctrls.estudios.label"
            :disabled="!is_edit">          
          </v-textarea>

          <compfecha
            v-bind="$input"
            :schema="schema.ctrls.acceso"
            :edicion="false">          
          </compfecha>         
        </div>
      </div>
    </v-sheet>
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btramto = () => plugs.groute("btramto_new.vue", "comp");
  const compfecha = () => plugs.groute("compfecha.vue", "comp");
  const common_Docs = () => plugs.groute("common_Docs.vue", "comp");
  const compImagen = () => plugs.groute("compImagen.vue", "comp");

  export default {
    mixins: [mixinMto],
    components: { base_Header, btramto, compfecha, compImagen, common_Docs },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}},
      disparo: { type:Boolean, default:false },
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'jefeszona_M',
        stName:'stMjefeszona',

        showpass1: false,
        showpass2: false
      };
    },

    computed: {
      // data provider para el combo de situación
      get_itemsSituacion() {
        return this.$store.state.datos_iniciales.situ;
      },

      // A la espera de lo que diga Miguel. Data provider para el combo de grupo
      get_estadoCivil() {
        return [
          { id: "0", name: "" },
          { id: "1", name: "Soltero/a" },
          { id: "2", name: "Casado/a" },
          { id: "3", name: "Divorciado/a" },
          { id: "4", name: "Viudo/a" }
        ];
      }
    },

    methods: {

      ini_data(){
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;        
        
        // configuro Entorno 
        this.Entorno.header.titulo = "Coordinador de Servicios - Mantenimiento"; 
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo["standardM"]));
                     
        // items adicionales          
      },
      

      // gestor de eventos
      /* event_capture(evt) {
        console.log(
          "*** event_capture jefeszona_M. Accion: " + evt.accion + " ***"
        );

        if (this.$isFunction(this[evt.accion])) {
          this[evt.accion](evt);
          return;
        }
        this.acciones_Mto(evt);
      } */
    }
  };
</script>
